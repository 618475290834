import React from 'react'
import { Row, Col, Carousel } from 'antd';
import { Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';


const AnyReactComponent = ({ text }) => <div>{text}</div>;

const defaultProps = {
    center: {
        lat: 10.99835602,
        lng: 77.01502627
    },
    zoom: 11
};

const About = () => {
    const { t } = useTranslation();
    return (
        <div >
           <h1>About</h1>
        </div>
    )
}
export default About