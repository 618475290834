import React, { useState, useEffect } from 'react';
import { Card, Skeleton } from 'antd';
import { getOneMeals } from '../../services/apiService'; // Adjust this import as needed
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom'; // To parse the URL query string

const { Meta } = Card;

const MealDetail = () => {
    const [mealDetail, setMealDetail] = useState(null);
    const [liked, setLiked] = useState(false);
    const [loading, setLoading] = useState(true);
    const location = useLocation(); // Hook from React Router to access current location

    useEffect(() => {
        const mealId = location.search.split("?")[1];
        //const mealId = new URLSearchParams(location.search).get('id'); // Assuming your URL is like /Meal?id=564653
        const savedLikes = JSON.parse(localStorage.getItem('likedMeals')) || {};

        const fetchMealDetail = async () => {
            setLoading(true);
            try {
                const details = await getOneMeals(mealId);
                setMealDetail(details.meals[0]); // Assuming getOneMeals returns an object with a meals array
                setLiked(!!savedLikes[mealId]); // Check if the meal is liked
            } catch (error) {
                console.error("Failed to fetch meal details:", error);
            } finally {
                setLoading(false);
            }
        };

        if (mealId) {
            fetchMealDetail();
        }
    }, [location]);

    const toggleLike = () => {
        const updatedLikes = {
            ...JSON.parse(localStorage.getItem('likedMeals')) || {},
            [mealDetail.idMeal]: !liked
        };
        localStorage.setItem('likedMeals', JSON.stringify(updatedLikes));
        setLiked(!liked);
    };

    if (loading) {
        return <Skeleton active />;
    }

    return (
        <div style={{ margin: '20px' }}>
            {mealDetail ? (
                <Card
                title ={mealDetail.strMeal}
                    hoverable
                    cover={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25em' }}>
                            <img style={{ maxWidth: '100%', maxHeight: '100%' }} alt={mealDetail.strMeal} src={mealDetail.strMealThumb} />
                        </div>
                    }
                    actions={[
                        liked ?
                            <HeartFilled key="unlike" onClick={toggleLike} /> :
                            <HeartOutlined key="like" onClick={toggleLike} />
                    ]}
                >
                    <Meta description={mealDetail.strInstructions} />

                </Card>
            ) : <p>Meal not found.</p>}
        </div>
    );
};

export default MealDetail;
