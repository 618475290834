import React, { useState, useEffect } from 'react';
import { List, Avatar, Card, Skeleton, Row } from 'antd';
import { getOneMeals } from '../../services/apiService'; // Adjust this import as needed
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for React Router v6

const { Meta } = Card;

const LikedMeals = () => {
    const navigate = useNavigate(); // Hook for navigation
    const handleCardClick = (categoryName) => {
        // Function to navigate to the desired path
        navigate(`/Meal?${encodeURIComponent(categoryName)}`);
    };
    const [likedMeals, setLikedMeals] = useState([]);
    const [likedMealsIds, setLikedMealsIds] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const savedLikes = JSON.parse(localStorage.getItem('likedMeals')) || {};
        setLikedMealsIds(savedLikes);

        const fetchLikedMeals = async () => {
            setLoading(true);
            try {
                const likedIds = Object.keys(savedLikes).filter(id => savedLikes[id]);

                const fetchPromises = likedIds.map(id => getOneMeals(id));
                const mealsDetails = await Promise.all(fetchPromises);

                setLikedMeals(mealsDetails.flat());
            } catch (error) {
                console.error("Failed to fetch liked meals:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchLikedMeals();
    }, []);

    const toggleLike = (idMeal) => {
        const updatedLikes = {
            ...likedMealsIds,
            [idMeal]: !likedMealsIds[idMeal]
        };
        setLikedMealsIds(updatedLikes);
        localStorage.setItem('likedMeals', JSON.stringify(updatedLikes));

        // Optionally, filter out unliked meals immediately from the UI
        if (!updatedLikes[idMeal]) {
            setLikedMeals(likedMeals.filter(meal => meal.meals[0].idMeal !== idMeal));
        }
    };

    if (loading) {
        return <Skeleton active />;
    }

    return (
        <div>
            <Row justify="center">
                <h1>My Favourites</h1>
            </Row>
            <div style={{ margin: '20px' }}>
                <List
                    grid={{
                        gutter: 16,
                        xs: 1, // 1 item per line on extra small (mobile) screens
                        sm: 2, // 2 items per line on small screens (optional adjustment)
                        md: 3, // 3 items per line on medium screens (optional adjustment)
                        lg: 4, // 4 items per line on large screens
                        xl: 4, // 4 items per line on extra large screens
                        xxl: 4, // 4 items per line on extra extra large screens (optional)
                    }} dataSource={likedMeals}
                    renderItem={meal => (
                        <List.Item>
                            <Card
                                hoverable
                                cover={<img onClick={() => handleCardClick(meal.meals[0]?.idMeal)} alt={meal.meals[0]?.strMeal} src={meal.meals[0].strMealThumb} />}
                                actions={[
                                    likedMealsIds[meal.meals[0]?.idMeal] ?
                                        <HeartFilled key="unlike" onClick={() => toggleLike(meal.meals[0]?.idMeal)} /> :
                                        <HeartOutlined key="like" onClick={() => toggleLike(meal.meals[0]?.idMeal)} />
                                ]}
                            >
                                <Meta onClick={() => handleCardClick(meal.meals[0]?.idMeal)} title={meal.meals[0]?.strMeal} />
                            </Card>
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};

export default LikedMeals;
