import {
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import HomePage from "../pages/homePage/HomePage";
import Menu from "../pages/menu/Menu";
import Favourites from "../pages/favourites/Favourites";
import Generator from "../pages/generator/Generator";
import About from "../pages/about/About";
import List from "../pages/list/List";
import Meal from "../pages/meal/Meal";
import MainLayout from "../layout/mainLayout/MainLayout";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'


const AppRoute = (prop) => {

    const [token, setToken] = useState();
    const [completed, setCompleted] = useState();
    const location = useLocation()
    const [props, setProps] = useState(prop);
    //let props = {...prop,token:token}
    useEffect(() => {
        let Mytoken = window.localStorage.getItem('token')
        let Mycompleted = window.localStorage.getItem('Is_completed')
        setToken(JSON.parse(Mytoken))
        setCompleted(JSON.parse(Mycompleted))
        if (Mytoken) {
            Mytoken = Mytoken.replace(/^"|"$/g, '');
        }
        //console.log("Mycompleted",Mycompleted);
        //console.log("Mytoken",Mytoken);
        //props = {...props,token:Mytoken}
        setProps({ ...props, token: Mytoken, completed: Mycompleted })
    }, [window.localStorage, location, location?.pathname])

    useEffect(() => {
        console.log("completed", completed);
    }, [completed])

    const AuthRout = (propses) => {
        const { condtion } = propses
        if (condtion) {
            return (
                propses.children
            )
        } else {
            <MainLayout>
                <HomePage {...props} />
            </MainLayout>
        }


    }


    return (


        <Routes>
            <Route
                exact
                path="/"
                element={
                    <MainLayout>
                        <HomePage {...props} />
                    </MainLayout>
                }
            />

            <Route
                exact
                path="/Home"
                element={
                    <MainLayout>
                        <HomePage {...props} />
                    </MainLayout>
                }
            />

            <Route
                exact
                path="/Menu"
                element={
                    <MainLayout>
                        <Menu {...props} />
                    </MainLayout>
                }
            />

            <Route
                exact
                path="/Favourites"
                element={
                    <MainLayout>
                        <Favourites {...props} />
                    </MainLayout>
                }
            />

            <Route
                exact
                path="/Generator"
                element={
                    <MainLayout>
                        <Generator {...props} />
                    </MainLayout>
                }
            />
            <Route
                exact
                path="/About"
                element={
                    <MainLayout>
                        <About {...props} />
                    </MainLayout>
                }
            />
            <Route
                exact
                path="/List"
                element={
                    <MainLayout>
                        <List {...props} />
                    </MainLayout>
                }
            />
            <Route
                exact
                path="/Meal"
                element={
                    <MainLayout>
                        <Meal {...props} />
                    </MainLayout>
                }
            />
        </Routes>






    );
};

export default AppRoute;