import React, { useState, useEffect, Suspense } from 'react';
import { getCategories } from '../../services/apiService'; // Adjust the import path as needed
import { Avatar, Card, Skeleton, Switch, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for React Router v6


const AnyReactComponent = ({ text }) => <div>{text}</div>;
const { Meta } = Card;

const defaultProps = {
  center: {
    lat: 10.99835602,
    lng: 77.01502627
  },
  zoom: 11
};

const Menu = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Hook for navigation
  const handleCardClick = (categoryName) => {
    // Function to navigate to the desired path
    navigate(`/List?${encodeURIComponent(categoryName)}`);
  };
  const [categories, setCategoriesData] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    const loadCategoriesData = async () => {
      setLoading(true);
      try {
        const categories = await getCategories();
        setCategoriesData(categories);
      } catch (error) {
        console.log(error); // Or handle the error as needed
      }
      setLoading(false);
    };

    loadCategoriesData();
  }, []);
  const truncateDescription = (description) => {
    return description.split(" ").slice(0, 6).join(" ") + (description.split(" ").length > 10 ? "..." : "");
  };

  if (loading) {
    return <Skeleton active avatar />;
  }

  return (
    <div >
      <Row justify="center">
        <h1>Menu</h1>
      </Row>
      <>
        <Row justify="center" gutter={16}>
          {categories?.categories?.map((category) => (
            <Col xs={24} sm={12} md={10} lg={8} xl={6} key={category.idCategory}>
              <Card
                hoverable
                style={{
                  width: '100%', // Adjusted to fill the column width
                  margin: 20,
                }}
                cover={<img alt={category.strCategory} src={category.strCategoryThumb} />}
                onClick={() => handleCardClick(category.strCategory)} // Making the entire card clickable
              >
                <Meta
                  title={category.strCategory}
                  description={truncateDescription(category.strCategoryDescription)}
                />
              </Card>
            </Col>
          ))}
        </Row>
      </>
    </div>
  )
}
export default Menu