import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, theme, Button } from 'antd';
import {
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FileOutlined,
  HomeOutlined,
  ThunderboltOutlined,
  HeartOutlined,
  ProductOutlined,
} from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
    // Note: Removed onClick from here because we'll handle navigation differently
  };
}

const MainLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const navigate = useNavigate(); // Correctly placed useNavigate hook inside the component
  const colorBgContainer = '#f0f2f5'; // Adjust this to your actual desired background color
  const borderRadiusLG = '8px'; // Adjust this to your actual desired border radius

  // Adjusted the creation of items to be inside the component to access navigate
  const mainItems = [
    getItem('Home Page', '1', <HomeOutlined />),
    getItem('Menu', '2', <ProductOutlined />),
    getItem('My Favourites', '3', <HeartOutlined />),
    getItem('Random Meals', '4', <ThunderboltOutlined />),
    //getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
  ];
  const bottomItems =
    getItem('About Me', '5', <InfoCircleOutlined />);

  // To handle navigation, add an onClick handler to the Menu component instead
  const [activeKey, setActiveKey] = useState('1'); // Default to the first item being active
  const location = useLocation(); // Get the current location
  useEffect(() => {
    // Function to determine the active key based on the current route
    const determineActiveKey = (pathname) => {
      // Example logic to map pathname to activeKey
      switch (pathname) {
        case '/':
          return '1';
        case '/Home':
          return '1';
        case '/Menu':
          return '2';
        case '/Favourites':
          return '3';
        case '/Generator':
          return '4';
        case '/About':
          return '5';
        // Add more cases as needed
        default:
          return '';
      }
    };

    // Update the activeKey based on the current route
    setActiveKey(determineActiveKey(location.pathname));
  }, [location]); // Re-run this effect when location changes



  const onMenuClick = (event) => {
    // Check the key of the clicked item and navigate accordingly
    if (event.key === '1') {
      navigate('/Home');
    }
    if (event.key === '2') {
      navigate('/Menu');
    }
    if (event.key === '3') {
      navigate('/Favourites');
    }
    if (event.key === '4') {
      navigate('/Generator');
    }
    if (event.key === '5') {
      navigate('/About');
    }
    if (event.key !== '5') {
      setActiveKey(event.key);
    }
    else {
      setActiveKey('5');
    }
    // Handle other cases as needed
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      {/* <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <div>
  <Button
    type="primary"
    onClick={toggleCollapsed}
    style={{
      margin: 16,
    }}
  >
    {collapsed  ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
  </Button>
  <div className="demo-logo-vertical" />
  <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={mainItems} onClick={onMenuClick} selectedKeys={[activeKey]} />
    <div style={{marginTop:'auto'}}>
      <Menu theme="dark" mode="inline" items={[bottomItems]} onClick={onMenuClick} selectedKeys={[activeKey]} />
    </div>
    </div>
  </div>
</Sider> */}
      <Sider collapsed={collapsed} onCollapse={setCollapsed}>
        <div className="demo-logo-vertical" />
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Button
            type="default"
            onClick={toggleCollapsed}
            style={{
              margin: 16,
            }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={mainItems} onClick={onMenuClick} selectedKeys={[activeKey]} />
          <div style={{ marginTop: 'auto' }}>
            <Menu theme="dark" mode="inline" items={[bottomItems]} onClick={onMenuClick} selectedKeys={[activeKey]} />
          </div>
        </div>
      </Sider>

      <Layout>
        <Content
          style={{
            margin: '0 16px',
            height: '90vh', // تحديد ارتفاع الـ Content ليكون 100vh
            display: 'flex', // جعل الـ Content يستخدم Flexbox لتنظيم المحتويات
            flexDirection: 'column', // تحديد اتجاه المحتويات لتكون في عمود
          }}
        >
          <Breadcrumb
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              margin: '16px 0',
            }}
          >
            {/* <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item> */}
          </Breadcrumb>
          <div
            style={{
              padding: 24,
              overflowY: 'auto', // تمكين التمرير العمودي في حال تجاوز المحتوى الارتفاع
              flex: 1, // جعل الـ div يملأ المساحة المتبقية بعد Breadcrumb
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              scrollbarWidth: 'thin', // Firefox
              scrollbarColor: '#1677ff #00152900', // Firefox, "thumb and track color"
              // WebKit browsers
              '::-webkit-scrollbar': {
                width: '8px',
              },
              '::-webkit-scrollbar-track': {
                background: '#00152900',
              },
              '::-webkit-scrollbar-thumb': {
                background: '#1677ff',
              },
            }}
          >
            {props.children}
          </div>
        </Content>
        {/* <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Oxinus Test ©{new Date().getFullYear()} Created by Osama Kutit
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default MainLayout;
