import React from 'react'
import AppRoute from './appRoute/AppRoute'
import { BrowserRouter } from 'react-router-dom'
import './App.css';

const NewApp = () => {
    return (
        <BrowserRouter
            forceRefresh={true}
            basename={"/"}>
            <AppRoute />
        </BrowserRouter>

    )
}

export default NewApp