import React, { useState } from 'react';
import { Card, Skeleton, Button, Row } from 'antd';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { random } from '../../services/apiService'; // Adjust this import as needed
import { useNavigate } from 'react-router-dom'; // Import useNavigate for React Router v6


const { Meta } = Card;

const Generator = () => {
    const navigate = useNavigate();
    const handleCardClick = (categoryName) => {
        navigate(`/Meal?${encodeURIComponent(categoryName)}`);
    };
    const [mealDetail, setMealDetail] = useState({
        strMeal: 'Random Meals',
        strMealThumb: 'https://www.themealdb.com/images/ingredients/Lime.png',
        strInstructions: 'Click On Generate To Load New Random Meals',
        idMeal: 'fakeId'
    });
    const [liked, setLiked] = useState(false);

    const fetchMealDetail = async () => {
        try {
            const details = await random(); // Assuming 'random' fetches a random meal
            setMealDetail(details.meals[0]);
            const savedLikes = JSON.parse(localStorage.getItem('likedMeals')) || {};
            setLiked(!!savedLikes[details.meals[0].idMeal]);
        } catch (error) {
            console.error("Failed to fetch meal details:", error);
        } finally {
        }
    };

    const toggleLike = () => {
        const mealId = mealDetail.idMeal;

        // Only proceed if mealId is not 'fakeId'
        if (mealId !== 'fakeId') {
            const currentLikes = JSON.parse(localStorage.getItem('likedMeals')) || {};
            const updatedLikes = {
                ...currentLikes,
                [mealId]: !liked
            };

            // Update local storage and state only if mealId is not 'fakeId'
            localStorage.setItem('likedMeals', JSON.stringify(updatedLikes));
            setLiked(!liked);
        }
    };

    const truncateDescription = (description) => {
        return description.split(" ").slice(0, 8).join(" ") + (description.split(" ").length > 10 ? "..." : "");
    };

    return (
        <div >
            <Row justify="center">
                <h1>Random Meals</h1>
            </Row>
            <div style={{ margin: '20px' }}>
                <Row justify="center">
                    <Card
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 4,
                            xl: 4,
                            xxl: 4,
                        }}
                        hoverable
                        cover={<img onClick={() => handleCardClick(mealDetail?.idMeal)}
                            style={{ height: '25em', width: 'auto' }} alt={mealDetail?.strMeal} src={mealDetail?.strMealThumb} />}
                        actions={[
                            liked ?
                                <HeartFilled key="unlike" onClick={toggleLike} /> :
                                <HeartOutlined key="like" onClick={toggleLike} />,
                            <Button key="generate" type="primary" onClick={fetchMealDetail}>Generate</Button>
                        ]}
                    >
                        <Meta title={mealDetail.strMeal}
                            onClick={() => handleCardClick(mealDetail?.idMeal)}
                            description={truncateDescription(mealDetail?.strInstructions)} />
                    </Card>
                </Row>
            </div>
        </div >

    );
};

export default Generator;
