// apiService.js

const BASE_URL = 'https://www.themealdb.com/api';

// Fetch data from an endpoint
export const fetchData = async (endpoint) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`);
    if (!response.ok) throw new Error('Network response was not ok');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Rethrowing the error allows you to handle it where the function is called
  }
};

// Example specific API call
export const getCategories = () => fetchData('json/v1/1/categories.php');

export const random = () => fetchData('json/v1/1/random.php');


// Another specific API call
export const getOneCategories = (categoryName) => {
  const url = `json/v1/1/filter.php?c=${encodeURIComponent(categoryName)}`;
  return fetchData(url);
};

export const getOneMeals = (categoryName) => {
  const url = `json/v1/1/lookup.php?i=${encodeURIComponent(categoryName)}`;
  return fetchData(url);
};