import React from 'react'
import './homepage.css'; // Adjust the file name as necessary
import { Row, Button, Col, Carousel } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FileOutlined,
  HomeOutlined,
  ThunderboltOutlined,
  HeartOutlined,
  ProductOutlined,
} from '@ant-design/icons';


const AnyReactComponent = ({ text }) => <div>{text}</div>;

const defaultProps = {
  center: {
    lat: 10.99835602,
    lng: 77.01502627
  },
  zoom: 11
};

const HomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div >
      <Row justify="center">
        <Col>
          <h1>Home Page</h1>
        </Col>
      </Row>
      <Row style={{ paddingBottom: "25px" }} justify="center" gutter={[16, 16]}>
        {/* First row with two buttons */}
        <Col xs={24} sm={12} md={10} lg={8} xl={6}>
          <Button className="custom-button menu-button" onClick={() => navigate('/Menu')} style={{ height: "5em" }} size="large" block> <ProductOutlined /> Meun</Button>
        </Col>
        <Col xs={24} sm={12} md={10} lg={8} xl={6}>
          <Button className="custom-button fav-button" onClick={() => navigate('/Favourites')} style={{ height: "5em" }} size="large" block><HeartOutlined />Favourites</Button>
        </Col>
      </Row>
      <Row justify="center" gutter={[16, 16]}>
        {/* Second row with one button */}
        <Col xs={24} sm={24} md={20} lg={16} xl={12}>
          <Button className="custom-button gen-button" onClick={() => navigate('/Generator')} style={{ height: "5em" }} size="large" block><ThunderboltOutlined /> Random Meals</Button>
        </Col>
      </Row>
    </div>
  )
}
export default HomePage