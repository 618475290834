import React, { useState, useEffect } from 'react';
import { getOneCategories } from '../../services/apiService'; // Adjust as needed
import { Skeleton, List, Avatar } from 'antd';
import { useLocation } from 'react-router-dom'; // Assuming you're using React Router
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for React Router v6


const Meals = () => {
  const navigate = useNavigate(); // Hook for navigation
  const handleCardClick = (categoryName) => {
    // Function to navigate to the desired path
    navigate(`/Meal?${encodeURIComponent(categoryName)}`);
  };
  const [meals, setMeals] = useState([]);
  const [likedMeals, setLikedMeals] = useState(() => {
    // Retrieve liked meals from local storage or initialize to an empty object
    const savedLikes = localStorage.getItem('likedMeals');
    return savedLikes ? JSON.parse(savedLikes) : {};
  });
  const [loading, setLoading] = useState(true);
  const location = useLocation(); // Get the current URL location

  useEffect(() => {
    const fetchMeals = async () => {
      const query = location.search.split("?")[1];
      if (query) {
        try {
          setLoading(true);
          const data = await getOneCategories(query);
          setMeals(data.meals);
        } catch (error) {
          console.error("Failed to fetch meals:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMeals();
  }, [location]);

  // Save likedMeals to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('likedMeals', JSON.stringify(likedMeals));
  }, [likedMeals]);

  const toggleLike = (idMeal) => {
    const isLiked = likedMeals[idMeal];
    setLikedMeals({ ...likedMeals, [idMeal]: !isLiked });
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <div >
      <List
        itemLayout="horizontal"
        dataSource={meals}
        renderItem={meal => (
          <List.Item
            style={{ padding: '20px 0', minHeight: '100px' }} // Adjust padding and minHeight as needed
            actions={[
              likedMeals[meal.idMeal] ?
                <a key="toggle-like" onClick={() => toggleLike(meal.idMeal)}><HeartFilled /></a> :
                <a key="toggle-like" onClick={() => toggleLike(meal.idMeal)}><HeartOutlined /></a>
            ]}
          >
            <Skeleton loading={loading} avatar active>
              <List.Item.Meta
                onClick={() => handleCardClick(meal.idMeal)} // Making the entire card clickable
                avatar={<Avatar src={meal.strMealThumb} size={64} />}
                title={meal.strMeal}
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Meals;
